import { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from "reactstrap";

// import

// images
import { PITCH_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";

const PitchViewDetails = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleMediaClick = (media) => {
    console.log(media, "allmedia");
    setSelectedMedia(media);
    setShowModal(true);
  };

  const isVideo = (url) => {
    const videoFormats = ["mp4", "webm", "ogg"];
    const extension = url?.split(".").pop().toLowerCase();
    return videoFormats.includes(extension);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data?.uploads?.length > 1 ? 1 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getData = catchAsync(async () => {
    const res = await PITCH_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data && data[0];
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Preview details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(-1)}>
                  <Link to="#">Manage Pitch</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Preview details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <h5 className="text-white">Banner Image</h5>

              <div className="border rounded">
                <img
                  src={data?.bannerImage}
                  style={{
                    width: "100%",
                    height: 400,
                    objectFit: "contain",
                  }}
                />
              </div>

              <h5 className="text-white mt-4">Uploads</h5>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ border: "1px solid white", padding: "10px" , borderRadius : "5px"  }}
              >
                {/* {data?.uploads && data?.uploads?.length !== 0 ? (
                <>
                  {" "}
                  <div
                    className="preivew_images_wrap"
                    style={{ width: "100%", overflow: "hidden" }}
                  >
                    <Slider {...settings}>
                      {data?.uploads?.map((item) => {
                        return (
                          <div
                            className="image_preview"
                            key={item?._id}
                            style={{
                              width: "100%",
                              height: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item?.link || item?.thumbNail}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <h5 className="text-white mt-4">No Uploads Found!</h5>
                  </div>
                </>
              )} */}

                {data?.uploads && data?.uploads?.length !== 0 ? (
                  <div
                    className="preview_images_wrap"
                    style={{
                      flexDirection: "row",
                      gap: "10px",
                      justifyContent: "center",
                      padding: "10px",
                      width: "20%",
                    }}
                  >
                    <Slider {...settings}>
                      {data?.uploads?.map((item) => (
                        <div
                          className="media_preview"
                          key={item?._id}
                          style={{
                            width: "150px",
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleMediaClick(item?.link || item?.thumbNail)
                          }
                        >
                          {isVideo(item?.link || item?.thumbNail) ? (
                            <video
                              src={item?.link || item?.thumbNail}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                cursor:"pointer",
                                  //  paddingTop:"50px"
                              }}
                              muted
                            />
                          ) : (
                            <img
                              src={item?.link || item?.thumbNail}
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <h5 className="text-white mt-4">No Uploads Found!</h5>
                  </div>
                )}
              </div>
              <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton className="bg-black"></Modal.Header>
                <Modal.Body className="text-center  h-50 bg-black">
                  {selectedMedia && isVideo(selectedMedia) ? (
                    <video
                      src={selectedMedia}
                      controls
                      autoPlay
                      style={{ maxWidth: "100%", maxHeight: "500px" }}
                    />
                  ) : (
                    <img
                      src={selectedMedia}
                      alt="Media Preview"
                      className="img-fluid"
                      style={{ maxHeight: "500px" }}
                    />
                  )}
                </Modal.Body>
              </Modal>
              <div className="author_form mt-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields">
                      <Label for="eventname">Pitch Name</Label>
                      <p className="text_line">{data?.name}</p>
                    </FormGroup>
                  </Col>
                  {/* <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Address</Label>
                      <p className="text_line">{data?.address}</p>
                    </FormGroup>
                  </Col> */}

                  {data?.description && (
                    <Col sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Description</Label>
                        <p className="text_line">{data?.description}</p>
                      </FormGroup>
                    </Col>
                  )}

                  <h5 className="text-white">User Information</h5>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">User Name</Label>
                      <p className="text_line">
                        {data?.userId?.fName ||
                          "" ||
                          "" + " " + (data?.userId?.lname || "")}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Email</Label>
                      <p className="text_line">{data?.userId?.email}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <h5></h5>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default PitchViewDetails;
