import React from "react";
import Talent from "Pages/Profile/Talent";


export default function Dashtalentpagetheme() {
  return (
    <>
       <Talent/>
    </>
  );
}
