import React, { useState } from "react";
import Fade from "react-reveal";
import Contactsupport from "./Cmscomponents/contactsupport";

import Socialurls from "./Cmscomponents/socialurls";
import TermsconditionAU from "./Cmscomponents/TermsconditionAU";

// images
import Nodatagif from "../../../assets/images/Nodat-found.gif";
import CMSTable from "./CMSTable";
import Faqtable from "./Cmscomponents/faqs/Faqtable";

const Cmstabletab = () => {
  const [state, setState] = useState({ tab: "ABOUT_US" });

  return (
<section className="tabbing_common">
  <div className="tab-frame user_frame">
    <div className="clearfix">
      <input
        type="radio"
        name="tab"
        id="about_us"
        checked={state.tab === "ABOUT_US"}
        onClick={() => setState({ tab: "ABOUT_US" })}
        style={{ display: "none" }} // Hide the radio input
      />
      <label 
        htmlFor="about_us" 
        style={{ 
          backgroundColor: state.tab === "ABOUT_US" ? "white" : "transparent",
          color: state.tab === "ABOUT_US" ? "black" : "gray" // Set text color to black for active tab, gray for inactive
        }} 
      >
        About Us
      </label>

      <input
        type="radio"
        name="tab"
        id="privacy_policy"
        checked={state.tab === "PRIVACY_POLICY"}
        onClick={() => setState({ tab: "PRIVACY_POLICY" })}
        style={{ display: "none" }} // Hide the radio input
      />
      <label 
        htmlFor="privacy_policy" 
        style={{ 
          backgroundColor: state.tab === "PRIVACY_POLICY" ? "white" : "transparent",
          color: state.tab === "PRIVACY_POLICY" ? "black" : "gray" // Set text color to black for active tab, gray for inactive
        }} 
      >
        Privacy Policy
      </label>

      <input
        type="radio"
        name="tab"
        id="refund_policy"
        checked={state.tab === "REFUND_POLICY"}
        onClick={() => setState({ tab: "REFUND_POLICY" })}
        style={{ display: "none" }} // Hide the radio input
      />
      <label 
        htmlFor="refund_policy" 
        style={{ 
          backgroundColor: state.tab === "REFUND_POLICY" ? "white" : "transparent",
          color: state.tab === "REFUND_POLICY" ? "black" : "gray" // Set text color to black for active tab, gray for inactive
        }} 
      >
        Refund Policy
      </label>

      <input
        type="radio"
        name="tab"
        id="terms_conditions"
        checked={state.tab === "TERMS_CONDITIONS"}
        onClick={() => setState({ tab: "TERMS_CONDITIONS" })}
        style={{ display: "none" }} // Hide the radio input
      />
      <label 
        htmlFor="terms_conditions" 
        style={{ 
          backgroundColor: state.tab === "TERMS_CONDITIONS" ? "white" : "transparent",
          color: state.tab === "TERMS_CONDITIONS" ? "black" : "gray" // Set text color to black for active tab, gray for inactive
        }} 
      >
        Terms and Conditions
      </label>
      <input
        type="radio"
        name="tab"
        id="faqs"
        checked={state.tab === "faqs"}
        onClick={() => setState({ tab: "faqs" })}
        style={{ display: "none" }} // Hide the radio input
      />
      <label 
        htmlFor="faqs" 
        style={{ 
          backgroundColor: state.tab === "faqs" ? "white" : "transparent",
          color: state.tab === "faqs" ? "black" : "gray" // Set text color to black for active tab, gray for inactive
        }} 
      >
        FAQ's
      </label>
    </div>

    <div className="pending_user_content mt-4">
      {state.tab === "ABOUT_US" && (
        <Fade>
          {/* Content for About Us */}
          <CMSTable name={state?.tab} />
        </Fade>
      )}

      {state.tab === "PRIVACY_POLICY" && (
        <Fade>
          {/* Content for Privacy Policy */}
          <CMSTable name={state?.tab} />
        </Fade>
      )}

      {state.tab === "REFUND_POLICY" && (
        <Fade>
          {/* Content for Refund Policy */}
          <CMSTable name={state?.tab} />
        </Fade>
      )}

      {state.tab === "TERMS_CONDITIONS" && (
        <Fade>
          {/* Content for Terms and Conditions */}
          <CMSTable name={state?.tab} />
        </Fade>
      )}
        {state.tab === "faqs" && (
        <Fade>
          {/* Content for Terms and Conditions */}
          <Faqtable/>
        </Fade>
      )}
    </div>
  </div>
</section>



  );
};

export default Cmstabletab;
