import * as CONST from "./actionTypes";

const initialState = {
  isLogin: false,
  loading: false,
  profileData: null, // Full profile data
  pendingRequestSubViewNames: [], // Filtered pending request names
  pendingRequestSubAddNames: [],
  pendingRequestSubDeletNames: [],
  userPermission: null,
  eventPermission: null,
  pitchPermission: null,
  postPermission: null,
  chatRoomPermission: null,
  subAdminPermission: null,
  cmsAdminPermission:null,
  notificationPermission:null
};

const ProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CONST.GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case CONST.PROFILE_SUCCESS:
      // Process filtered pending requests here when profile data is fetched

      const pendingRequestSubViewNames =
        payload?.data?.permission
          ?.find((item) => item?.text === "Pending Requests")
          ?.subMenuItems?.filter((subItem) => subItem.isView === true)
          ?.map((subItem) => subItem.name) || [];
      const pendingRequestSubAddNames =
        payload?.data?.permission
          ?.find((item) => item?.text === "Pending Requests")
          ?.subMenuItems?.filter((subItem) => subItem?.isAdd === true)
          ?.map((subItem) => subItem.name) || [];
      const pendingRequestSubDeletNames =
        payload?.data?.permission
          ?.find((item) => item?.text === "Pending Requests")
          ?.subMenuItems?.filter((subItem) => subItem?.isDelete === true)
          ?.map((subItem) => subItem.name) || [];
      const userPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Users"
      );
      const eventPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Events"
      );
      const pitchPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Pitches"
      );
      const postPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Posts"
      );
      const chatRoomPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Chatroom"
      );
      const subAdminPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Sub Admin"
      );
      const cmsAdminPermission = payload?.data?.permission?.find(
        (item) => item?.text === "CMS Pages"
      );
      const notificationPermission = payload?.data?.permission?.find(
        (item) => item?.text === "Notification"
      );
   
      return {
        ...state,
        isLogin: true,
        loading: false,
        profileData: payload?.data, // Store the full profile data
        pendingRequestSubViewNames, // Store filtered pending request names
        pendingRequestSubAddNames,
        pendingRequestSubDeletNames,
        userPermission,
        eventPermission,
        pitchPermission,
        postPermission,
        chatRoomPermission,
        subAdminPermission,
        cmsAdminPermission,
        notificationPermission
        
      };
    case CONST.PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isLogin: false,
        profileData: null,
        pendingRequestSubViewNames: [], // Filtered pending request names
        pendingRequestSubAddNames: [],
        pendingRequestSubDeletNames: [],
        userPermission: null,
        eventPermission: null,
        pitchPermission: null,
        postPermission: null,
        chatRoomPermission: null,
        subAdminPermission: null,
        cmsAdminPermission:null,
        notificationPermission:null
      };
    default:
      return state;
  }
};

export default ProfileReducer;
