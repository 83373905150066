import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from "reactstrap";
import { USER_DETAILS } from "services/ApiCalls";
import {
  catchAsync,
  checkResponse,
  removeUnderScore,
} from "utilities/utilities";
import noImg from "../../../../../assets/images/no-img.png";
import moment from "moment";

const UserViewDetails = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const getData = catchAsync(async () => {
    const res = await USER_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data;
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  console.log(data);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Preview details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(-1)}>
                  <Link to="#">Manage Users</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Preview details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <h5 className="text-white"> Profile Image</h5>

              <div className="profile_img d-flex justify-content-center">
                <img
                  src={data?.profileImage || noImg}
                />
              </div>

              <div className="author_form mt-5">
                <h5 style={{ color: "white" }}>Personal Details</h5>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields d-flex align-items-center gap-3">
                      <Label for="eventname">First Name</Label>
                      <p className="text_line">{data?.fname}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields">
                      <Label for="eventname">Last Name</Label>
                      <p className="text_line">{data?.lname}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Email</Label>
                      <p className="text_line">{data?.email}</p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Mobile Number</Label>
                      <p className="text_line">
                        {data?.countryCode ? "+" : ""}
                        {data?.countryCode} {data?.mobileNumber}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <Label for="Category">Gender</Label>
                      <p className="text_line">{data?.gender}</p>
                    </FormGroup>
                  </Col>
                  {data?.accountType && (
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Account Type</Label>
                        <p className="text_line">
                          {removeUnderScore(data?.accountType)}
                        </p>
                      </FormGroup>
                    </Col>
                  )}
                  {data?.headline && (
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Headline</Label>
                        <p className="text_line">{data?.headline}</p>
                      </FormGroup>
                    </Col>
                  )}
                  {data?.title && (
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Title</Label>
                        <p className="text_line">{data?.title}</p>
                      </FormGroup>
                    </Col>
                  )}
                  {data?.link && (
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Link</Label>
                        <p className="text_line">{data?.link}</p>
                      </FormGroup>
                    </Col>
                  )}
                  {data?.description && (
                    <Col sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Biography</Label>
                        <p className="text_line">{data?.description}</p>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {data?.details && data?.details?.companies?.length !== 0 ? (
                  <>
                    <h5 style={{ color: "white" }}>Experience</h5>

                    {data?.details?.companies?.map((item) => {
                      return (
                        <Row key={item?._id}>
                          <Col lg={6} md={6} sm={12}>
                            <FormGroup className="flex_fields">
                              <Label for="eventname">Company Name</Label>
                              <p className="text_line">{item?.name}</p>
                            </FormGroup>
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <FormGroup className="flex_fields">
                              <Label for="eventname">Title</Label>
                              <p className="text_line">{item?.title}</p>
                            </FormGroup>
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <FormGroup className="flex_fields">
                              <Label for="eventname">Start Date</Label>
                              <p className="text_line">
                                {moment(item?.startDate).format("YYYY/MM/DD")}
                              </p>
                            </FormGroup>
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <FormGroup className="flex_fields">
                              <Label for="eventname">End Date</Label>
                              <p className="text_line">
                                {moment(item?.endDate).format("YYYY/MM/DD")}
                              </p>
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup className="position-relative flex_fields">
                              <Label for="Category">Description</Label>
                              <p className="text_line">{item?.description}</p>
                            </FormGroup>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default UserViewDetails;
