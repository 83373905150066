import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, Button, Col, Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { Fade } from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ADD_TERMS_CONDITION, CMS_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import Loading from "Component/common/Loading";

// Zod schema for form validation
const schema = z.object({
  content: z.string().min(1, { message: "Content is required" }),
  name: z.string().min(1, { message: "Name is required" }),
  type: z
    .union([
      z.string().nonempty({ message:"Type is required" }),
      z.number(),
    ])
    .refine((value) => value !== undefined, {
      message: "Type is required",
    }),
});

const TermsconditionAU = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [data, setData] = useState();
  const { id } = useParams();
  const EditId = !!id;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  // Handle form submission
  const onSubmit = async (data) => {
    setSubmitLoader(true);
    let newData = { ...data, type: +data?.type };
    const res = await ADD_TERMS_CONDITION(
      id ? { ...newData, _id: id } : newData
    );

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/cms",
      navigate,
      setLoader: setSubmitLoader,
    });
    reset(); // Reset form after submission
  };

  const getData = catchAsync(async () => {
    const res = await CMS_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        setData(data);

        reset({
          content: data.content || "",
          type: +data.type || "",
          name: data?.name || "",
        });
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <>
      <section
        className="cmspage_tab addresources comon_dashpad"
        style={{ color: "white" }}
      >
        <div className="breadcrumb_set mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/cms">Manage CMS</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
            {EditId ? "Edit CMS" : "Add CMS"}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="cmstabs_wrap text-start p-4 mt-5">
          <div className="articletab author_form">
            <Fade>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Content Field (using React-Quill) */}
                <Form.Group controlId="formContent">
                  <Form.Label>Content</Form.Label>
                  <Controller
                    name="content"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactQuill
                        theme="snow"
                        {...field}
                        onChange={field.onChange}
                        style={{ color: "white" }}
                      />
                    )}
                  />
                  {errors.content && (
                    <Form.Text className="text-danger">
                      {errors.content.message}
                    </Form.Text>
                  )}
                </Form.Group>

                {/* Name Field */}
                <Form.Group controlId="formName" className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("name")}
                    isInvalid={!!errors.name}
                    style={{ color: "white", backgroundColor: "#343a40" }} // Set text color and background color
                  >
                    <option value="">Select an option</option>
                    <option value="ABOUT_US">ABOUT_US</option>
                    <option value="PRIVACY_POLICY">PRIVACY_POLICY</option>
                    <option value="REFUND_POLICY">REFUND_POLICY</option>
                    <option value="TERMS_CONDITIONS">TERMS_CONDITIONS</option>
                  </Form.Control>
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {/* Type Field */}
                <Form.Group controlId="formType" className="mt-3">
                  <Form.Label>User Type</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("type")}
                    isInvalid={!!errors.type}
                    style={{ color: "white" }}
                  >
                    <option value="">Select an option</option>
                    <option value={1}>Rising</option>
                    <option value={2}>Professional</option>
                    <option value={3}>Executive</option>
                    <option value={4}>Quick Register</option>
                  </Form.Control>
                  {errors.type && (
                    <Form.Control.Feedback type="invalid">
                      {errors.type.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {/* Submit Button */}
                <Col lg={12} className="mt-4">
                  <Button
                    className="export_csv w-100"
                    style={{ height: 50 }}
                    type="submit"
                  >
                    {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                  </Button>
                </Col>
              </Form>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsconditionAU;
