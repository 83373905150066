import React, { useEffect, useState, createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgBYF3xn7mV1L6opJvF7lAWiq7TTPnYs0",
  authDomain: "jammena-9a075.firebaseapp.com",
  projectId: "jammena-9a075",
  storageBucket: "jammena-9a075.appspot.com",
  messagingSenderId: "788679254213",
  appId: "1:788679254213:web:cdd63c585a562957a010ac",
  measurementId: "G-4RJLEWQ8GC"
};

// Firebase Initialization
const firebaseApp = initializeApp(firebaseConfig);

// Custom hook to use Firebase messaging
const useFirebaseMessaging = () => {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          const messaging = getMessaging(firebaseApp);

          // Request for FCM token
          getToken(messaging, {
            vapidKey:
              "BLSEHou_fwk_zjFy0yEEs88pRMbUk_g-RAVzcqD1knlMINLeQIFJHi4SGPiTaR3cVrjPcO1MP98xgygsXnYCNjw",
          })
            .then((currentToken) => {
              if (currentToken) {
                // console.log("FCM Token:", currentToken);
                setToken(currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token: ", err);
            });

          // Listen for foreground messages
          onMessage(messaging, (payload) => {
            // console.log("Message received in foreground: ", payload);
            setMessage(payload);

            // Show notification in the foreground
            if (Notification.permission === "granted") {
              const { title, body } = payload.notification;
              new Notification(title, { body });
            }
          });
        })
        .catch((err) => {
          console.log("Service Worker registration failed: ", err);
        });
    }
  }, []);

  return { token, message };
};

// Context to provide Firebase messaging data
export const FirebaseContext = createContext({ token: "", message: "" });

export const FirebaseProvider = ({ children }) => {
  const firebaseMessaging = useFirebaseMessaging();
  return (
    <FirebaseContext.Provider value={firebaseMessaging}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
