import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const SortByFilter = ({ setBody, orderByKey }) => {
  const [selectedOrder, setSelectedOrder] = useState('Z-A');
  const handleOrderChange = (order) => {
    setBody((prevBody) => ({
      ...prevBody,
      orderBy: orderByKey || "createdAt",
      order,
    }));
    const orderText = order === 1 ? 'A-Z' : 'Z-A';
    setSelectedOrder(orderText);
  };
  return (
    <div className="dropdown_col">
      <UncontrolledDropdown>
        <DropdownToggle caret> {selectedOrder}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleOrderChange(1)}>A-Z</DropdownItem>
          <DropdownItem onClick={() => handleOrderChange(-1)}>Z-A</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default SortByFilter;
