import { useEffect, useState } from "react";
import { Card, Col, Image, ListGroup, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from "reactstrap";
import { CHAT_DETAILS, USER_DETAILS } from "services/ApiCalls";
import {
  catchAsync,
  checkResponse,
  removeUnderScore,
} from "utilities/utilities";
import noImg from "../../../../../assets/images/no-img.png";
import moment from "moment";

const ChatViewDetails = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const getData = catchAsync(async () => {
    const res = await CHAT_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data;
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  console.log(data);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Preview details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(-1)}>
                  <Link to="#">Manage Chatroom</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Preview details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <h5 className="text-white"> Image</h5>

              <div className="profile_img d-flex justify-content-center">
                <img src={data?.image || noImg} />
              </div>

              <div className="author_form mt-5">
                <h5 style={{ color: "white" }}>Chatroom Details</h5>
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="flex_fields d-flex align-items-center gap-3">
                      <Label for="eventname">Group Name</Label>
                      <p className="text_line">{data?.name}</p>
                    </FormGroup>
                  </Col>

                  {data?.description && (
                    <Col sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Description</Label>
                        <p className="text_line">{data?.description}</p>
                      </FormGroup>
                    </Col>
                  )}
                  {data?.rejectReason && (
                    <Col sm={12}>
                      <FormGroup className="position-relative flex_fields">
                        <Label for="Category">Reject Reason</Label>
                        <p className="text_line">{data?.rejectReason}</p>
                      </FormGroup>
                    </Col>
                  )}
                  
                  <Col md={12}>
                    <Card style={{backgroundColor:"#201d35", color:"white"}}>
                    <h5 style={{ color: "white" }}>Members</h5>
                      {
                        data?.roomMembers && data?.roomMembers?.length!=0 ?
                        <>
                        
                        <ListGroup variant="flush" >
                        {data?.roomMembers?.map((member, index) => (
                          <ListGroup.Item key={index} style={{backgroundColor:"#201d35", color:"white"}}>
                            <div className="d-flex justify-content-between">
                     
                              <div>
                              <Image
                        src={member?.userId?.profileImage}
                        roundedCircle
                        width={40}
                        height={40}
                        alt="Profile"
                        className="me-3" // Adds margin between image and text
                      />
                                <strong>{member?.userId?.userName}</strong>{" "}
                             
                              </div>
                              <div>
                                {member?.isActive ? (
                                  <span className="text-success">Online</span>
                                ) : (
                                  <span className="text-danger">Offline</span>
                                )}
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                        </>:<><div className="d-flex justify-content-center">No Members Found!</div></>
                      }
                     
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default ChatViewDetails;
