import React, { useEffect, useState, createContext, useContext } from "react";
import URLS from "services/URLS";
import io from "socket.io-client";

export const SocketContext = createContext({
  socket: null,
  connectSocket: () => {},
});

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const connectSocket = () => {
    const userId = localStorage?.getItem("_id");
    const socketInstance = io(URLS.BASE_URL, {
      transports: ["websocket"],
      secure: true,
    });

    // When a connection is made  
    socketInstance.on("connect", (data) => {
      console.log(userId, "userId111");
      socketInstance.emit("login-admin", { userId });

      console.log(socketInstance, "socket8888");

      setSocket((p) => socketInstance);

      // Listen for incoming messages
    });
  };

  useEffect(() => {
    // Initialize the connection
    connectSocket();
    // Clean up on component unmount
    return () => {
      console.log("disconneect asdf");
      socket?.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, connectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
