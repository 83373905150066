import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Fade } from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { catchAsync, checkResponse } from "utilities/utilities";
import {
  ADD_EDIT_REPORTS_ISSUES,
  ADD_TALENT,
  REPORT_ISSUES_USER_LIST,
  TALENT_DETAILS,
} from "services/ApiCalls";
import Loading from "Component/common/Loading";

// Define your validation schema
const schema = z.object({
  name: z.string().min(1, "Name is required"),
});

const AddReportIssues = () => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState();

  const { id } = useParams();
  const EditId = !!id;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const submitHandler = async (data) => {
    setSubmitLoader(true);

    // Construct the request body
    const requestBody = id
      ? {
          _id: id,
          name: data.name,
        }
      : {
          name: data.name,
        };

    const res = await ADD_EDIT_REPORTS_ISSUES(requestBody);

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/report-issues",
      navigate,
      setLoader: setSubmitLoader,
    });
  };

  const getData = catchAsync(async () => {
    const res = await REPORT_ISSUES_USER_LIST({ _id: id });
    checkResponse({
      res,
      setData: (data) => {
        console.log(data, "data11111111");

        setData(data);

        reset({
          name: (data && data[0] && data[0]?.name) || "",
        });
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  console.log(data);
  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">
                {" "}
                {EditId ? "Edit " : "Add " + "Report Issues"}
              </h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/report-issues">Manage Report Issues</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {EditId ? "Edit " : "Add " + "Report Issues"}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                  {/* Name Input */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="name">Report Issue Name</Label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="text"
                            id="name"
                            placeholder=""
                            {...field}
                            style={{ color: "white" }}
                          />
                        )}
                      />
                      {errors.name && (
                        <span style={{ color: "red" }}>
                          {errors.name.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Col lg={6} className="mt-4">
                  <Button className="export_csv w-100" style={{ height: 50 }}>
                    {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </form>
      </section>
    </Fade>
  );
};

export default AddReportIssues;
