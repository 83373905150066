import { useState } from "react";
import ImageUploading from "react-images-uploading";
import { Button } from "reactstrap";

// import
import Loading from "Component/common/Loading";
import fileUploader from "utilities/fileUploader";
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Removeicon from "../../../../assets/svg/removeicon.svg";

const ImageUploader = ({ setImages, images }) => {
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const onImageUploadChange = async (imageList, addUpdateIndex) => {
    setImageUploadLoading(true);
    const urls = await fileUploader([imageList[addUpdateIndex].file]);
    if (!urls) {
      setImageUploadLoading(false);
      return;
    }
    setImages((p) => [...p, ...urls]);
    setImageUploadLoading(false);
  };

  const onImageRemoveHandler = (index) => {
    setImages((p) => {
      const newArr = [...p];
      newArr.splice(index, 1);
      return newArr;
    });
  };
  return (
    <div className="upload_imge_vedio">
      <ImageUploading multiple value={images} onChange={onImageUploadChange}>
        {({ imageList, onImageUpload, isDragging, dragProps }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="images_wrap_show d-flex flex-row overflow-x-scroll justify-content-start">
              {imageList.length > 0 ? (
                imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item position-relative"
                    style={{
                      minHeight: 300,
                      minWidth: 300,
                    }}
                  >
                    <img src={image} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => {
                          onImageRemoveHandler(index);
                        }}
                      >
                        <img
                          src={Removeicon}
                          alt="Remove"
                          className="img-fluid"
                        />
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="upload_text text-white w-100 text-center">
                  Upload Images
                </p>
              )}
            </div>

            <Button
              disabled={imageUploadLoading}
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
              className="add_plusimg my-3"
            >
              {imageUploadLoading ? (
                <Loading fullSize={true} />
              ) : (
                <img src={Plussvg} alt="" className="img-fluid" />
              )}
            </Button>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUploader;
