import dataHandler from "hooks/dataHandler";
import React, { useEffect, useState } from "react";
import { ADD_SUPPORT_MESSAGE, CHAT_LIST } from "services/ApiCalls";
import URLS from "services/URLS";
import io from "socket.io-client";
import EmojiPicker from "emoji-picker-react";
import MessageList from "./MessageList";
import styles from "./Chat.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { GrGallery } from "react-icons/gr";
import { showMessageSendReceiveTime } from "utilities/utilities";
import fileUploader from "utilities/fileUploader";

const SendMessageForm = ({ supportId, socket }) => {
  const [messageValue, setMessageValue] = useState("");

  const adminId = localStorage.getItem("_id");
  const [isEmojiOpen, setEmojiOpen] = useState(false);

  const sendMessage = async (messageValue, messageType) => {
    const body = {
      message: messageValue,
      messageType,
      senderId: adminId,
      supportId: supportId,
    };

    console.log("data1234");

    if (messageValue.trim() && socket) {
      socket.emit("send-support-admin-message", body, (data) => {
        console.log(data, "data1234");
      });
      console.log("asdf");
      setMessageValue((p) => "");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    sendMessage(messageValue, 1);
  };

  const emojiClick = (e) => {
    const { emoji } = e;
    setMessageValue((pre) => pre + emoji);
  };

  const onImageSend = async (e) => {
    const url = await fileUploader([e.target.files[0]]);

    if (!url || !url[0]) {
      return;
    }
    sendMessage(url[0], 3);
  };

  useEffect(() => {
    const closeEmojiContainer = (e) => {
      setEmojiOpen((p) => false);
    };
    const stopPropagationOnEmojiContainer = (e) => {
      e.stopPropagation();
    };
    window.addEventListener("click", closeEmojiContainer);
    const emojiContainer = document.getElementsByClassName("emojiContainer")[0];

    console.log(emojiContainer, "emojiContainer");
    emojiContainer?.addEventListener("click", stopPropagationOnEmojiContainer);

    return () => {
      window.removeEventListener("click", closeEmojiContainer);
      emojiContainer?.removeEventListener(
        "click",
        stopPropagationOnEmojiContainer
      );
    };
  }, []);

  return (
    <form onSubmit={submitHandler}>
      <Row className="d-flex  align-items-center">
        <Col md="2">
          <div className="position-relative d-flex gap-10">
            <Button
              onClick={(e) => {
                e.stopPropagation(); //
                setEmojiOpen((p) => !p);
              }}
              style={{
                backgroundColor: "#b0148e",
                borderColor: "#b0148e",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_0_1045)">
                  <path
                    d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389957 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520204 5.49836 1.37737 3.89472 2.63604 2.63604C3.89472 1.37737 5.49836 0.520204 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389957 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C17.9974 11.3862 17.0484 13.6738 15.3611 15.3611C13.6738 17.0484 11.3862 17.9974 9 18ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12988C1.50325 7.50032 1.35473 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4978 7.01155 15.7069 5.10516 14.3009 3.69911C12.8948 2.29307 10.9885 1.50219 9 1.5ZM13.2495 11.8095C13.3981 11.6772 13.488 11.4913 13.4996 11.2927C13.5111 11.0941 13.4433 10.8991 13.311 10.7505C13.1787 10.6019 12.9928 10.512 12.7942 10.5004C12.5956 10.4889 12.4006 10.5567 12.252 10.689C11.3352 11.461 10.1959 11.9202 9 12C7.80481 11.9203 6.66617 11.4616 5.7495 10.6905C5.60112 10.558 5.40618 10.4899 5.20758 10.5012C5.00898 10.5124 4.82298 10.6021 4.6905 10.7505C4.55803 10.8989 4.48993 11.0938 4.50118 11.2924C4.51243 11.491 4.60212 11.677 4.7505 11.8095C5.9414 12.8269 7.43567 13.4213 9 13.5C10.5643 13.4213 12.0586 12.8269 13.2495 11.8095ZM4.5 7.5C4.5 8.25 5.17125 8.25 6 8.25C6.82875 8.25 7.5 8.25 7.5 7.5C7.5 7.10218 7.34197 6.72065 7.06066 6.43934C6.77936 6.15804 6.39783 6 6 6C5.60218 6 5.22065 6.15804 4.93934 6.43934C4.65804 6.72065 4.5 7.10218 4.5 7.5ZM10.5 7.5C10.5 8.25 11.1713 8.25 12 8.25C12.8288 8.25 13.5 8.25 13.5 7.5C13.5 7.10218 13.342 6.72065 13.0607 6.43934C12.7794 6.15804 12.3978 6 12 6C11.6022 6 11.2206 6.15804 10.9393 6.43934C10.658 6.72065 10.5 7.10218 10.5 7.5Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_1045">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Button>
            <div
              className="position-absolute emojiContainer"
              style={{
                bottom: 40,
              }}
            >
              <EmojiPicker
                open={isEmojiOpen}
                className={`${styles.emojiClass} `}
                onEmojiClick={emojiClick}
              />
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="image">
                <input
                  type="file"
                  id="image"
                  className="d-none"
                  accept="image/*"
                  onChange={onImageSend}
                />
                <GrGallery size={20} fill="#b0148e" color="#b0148e" />
              </label>
            </div>
          </div>
        </Col>
        <Col md="8" data-mdb-input-init="" className="form-outline form-white ">
          <input
            className="form-control p-2"
            id="textAreaExample3"
            rows={2}
            defaultValue={""}
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
          />
        </Col>
        <Col md="2">
          <button
            type="submit"
            data-mdb-button-init=""
            data-mdb-ripple-init=""
            className="btn btn-light btn-lg btn-rounded float-end"
          >
            Send
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default SendMessageForm;
