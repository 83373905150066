import React from "react";
import { Form } from "react-bootstrap";
import { UserTypeArr } from "utilities/const";
import { removeUnderScore } from "utilities/utilities";

const UserTypeFilter = ({ body, setBody }) => {
  return (
    <div className="FilterSTatusWrap">
      <Form.Select
        defaultValue={body?.accountType}
        value={body?.accountType}
        style={{color:"#979797"}}
        onChange={(e) =>
          setBody((p) => ({
            ...p,
            accountType: e.target.value !== "all" ? e.target.value : "",
          }))
        }
      >
        <option value={"all"}>All User</option>
        {UserTypeArr?.map((option) => (
          <option value={option}>{removeUnderScore(option)}</option>
        ))}
      </Form.Select>
    </div>
  );
};

export default UserTypeFilter;
