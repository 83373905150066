import TableProfile from "Component/common/TableLayout/TableProfile";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from "reactstrap";

const ViewPopUp = ({ model, setModel }) => {
  console.log(model, "model");
  return (
    <Modal
      show={model?.show}
      onHide={() => setModel((p) => ({ ...p, show: false }))}
      centered
    >
      <Modal.Header closeButton className="bg-black"></Modal.Header>
      <Modal.Body className="text-center  h-50 bg-black">
        <Row>
          <Col lg="12 d-flex justify-content-center mb-4">
            <TableProfile image={model?.data?.userId?.profileImage} />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FormGroup className="flex_fields">
              <p for="" className="text-white mb-0">
                User Name
              </p>
              <p className="text_line">
                {model?.data?.userId?.fname
                  ? model?.data?.userId?.fname +
                    " " +
                    model?.data?.userId?.lname
                  : ""}
              </p>
            </FormGroup>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <FormGroup className="flex_fields">
              <p for="" className="text-white mb-0">
                Rating
              </p>
              <p className="text_line">{model?.data?.feedback}</p>
            </FormGroup>
          </Col>
          <Col lg={12} md={6} sm={12}>
            <FormGroup className="flex_fields d-flex flex-column gap-10">
              <p for="" className="text-white mb-0">
                Short Comment
              </p>
              <p className="text_line">{model?.data?.short_question}</p>
            </FormGroup>
          </Col>
          <Col lg={12} md={6} sm={12}>
            <FormGroup className="flex_fields d-flex flex-column gap-10">
              <p for="" className="text-white mb-0">
                Long Comment
              </p>
              <p className="text_line">{model?.data?.long_question || "-"}</p>
            </FormGroup>
          </Col>
          
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPopUp;
