import { useEffect, useState } from "react";
import {
  catchAsync,
  changePageToOne,
  checkResponse,
  removeUnwantedKeys,
} from "../utilities/utilities";
import { defaultDeleteModelState } from "../utilities/const";

const dataHandler = ({
  api,
  extraBody,
  dataToSet,
  dependencies,
  DoNotCallApiUntil,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const [total, setTotal] = useState(0);
  const [deleteModel, setDeleteModel] = useState(defaultDeleteModelState);
  const [body, setBody] = useState({
    search: "",
    // status: "",
    page: 1,
    limit: 10,
    orderBy: "createdAt",
    is_pagination: "true",
    order: -1,
    ...extraBody,
  });

  const getData = catchAsync(
    async (body) => {
      let donotCall = false;
      if (DoNotCallApiUntil?.length) {
        DoNotCallApiUntil.forEach((item) => {
          if (!body[item]) {
            donotCall = true;
          }
        });
      }
      if (donotCall) {
        return;
      }
      setLoader(true);
      const res = await api(removeUnwantedKeys(body));
      checkResponse({
        res,
        setData,
        setLoader,
        setTotal,
        dataToSet,
      });
    },
    { setLoader }
  );

  const refetch = () => {
    const newBody = changePageToOne(body, setBody);
    getData(newBody);
  };

  let oldData;
  const statusChangeCallBack = () => {
    setData((p) => oldData);
  };
  const statusChangeHandler = catchAsync(
    async (api, ind, key, value, refetchData = false) => {
      oldData = JSON.parse(JSON.stringify(data));

      setData((p) => {
        const newArr = JSON.parse(JSON.stringify(p));
        newArr[ind][key] = value;
        return newArr;
      });

      const res = await api();
      checkResponse({ res, showSuccess: true });
      if (refetchData) {
        refetch();
      }
    },
    null,
    statusChangeCallBack
  );

  const deleteHandler = catchAsync(async (api) => {
    const res = await api();
    console.log(res, "01111");
    checkResponse({ res, showSuccess: true });
    setDeleteModel({ show: false, dumpId: "" });
    refetch();
  });

  const paginationHandler = catchAsync(async (page) => {
    const newBody = {
      ...body,
      page,
    };
    setBody((p) => newBody);
    getData(newBody);
  });

  const searchHandler = () => {
    const newBody = { ...body, page: 1, search: body.search.trim() };
    setBody((p) => newBody);
    getData(newBody);
  };

  useEffect(() => {
    // if (body?.search?.trim() === "") {
    setIsMounted((p) => true);
    refetch();
    // }
  }, [body.search]);

  const depArr = [body.status, body.limit];
  if (dependencies && dependencies?.length > 0) {
    dependencies?.forEach((item) => {
      depArr.push(body[item]);
    });
  }

  useEffect(() => {
    if (!isMounted) return;
    refetch();
  }, depArr);

  return {
    data,
    setData,
    body,
    setBody,
    loader,
    setLoader,
    total,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    searchHandler,
    refetch,
    getData,
    statusChangeHandler,
    deleteHandler,
  };
};

export default dataHandler;
