import React, { useState } from "react";
import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

const DatePickerField = ({ label, placeholder,onChange }) => {
    const [formatDate,setFormatDate]=useState(null)
    const handleChange = (date) => {
        // Format date using moment and pass it up to the parent component
        if (date) {
            setFormatDate(date)
          onChange(date);
        } else {
          onChange(null);
          setFormatDate(null)
        }
      };
      const handleClean = () => {
        setFormatDate(null);
        onChange(null); // Pass null to parent component
      
      };
    
  return (
    <div className="calendar_set">
      <Stack direction="column" spacing={8} alignItems="flex-start">
        {/* <label>{label}</label> */}
        <DatePicker
          size="md"
          placeholder={placeholder}
          value={formatDate}
          onChange={handleChange}
          format="yyyy/MM/dd"
       
          cleanable={true} // Allow the date picker to be cleared
          onClean={handleClean} 
        />
      </Stack>
    </div>
  );
};

export default DatePickerField;
