import { makingOptionsFromArr } from "./utilities";

export const paginationLimitArr = [5, 10, 20, 50, 100, 200, 500, 1000];
export const defaultDeleteModelState = {
  show: false,
  dumpId: "",
};

export const UserTypeArr = [
  "RISING_STAR",
  "PROFESSIONAL",
  "EXECUTIVE",
  "QUICK_REGISTER",
];

const userStatusArr = ["pending", "approved", "decline"];

export const userStatusOptionArr = makingOptionsFromArr(userStatusArr);

export const eventStatusOptions = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Rejected",
  },
];

export const commonStatusOptions = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Rejected",
  },
];
export const navigationPaths = {
  createNewChatRoom: "/pending-request-chatroom",
  createEvent: "/pending-events",
  createPitch: "/pending-pitches",
  createPost: "/pending-posts",
  updateRoom: "/pending-request-chatroom",
  createNewRoom: "/pending-request-chatroom",
  supportChat: "/chat/support",
  createNewEvent: "/pending-events",
  createNewPitch: "/pending-pitches",
}
