import { useState } from "react";
import ImageUploading from "react-images-uploading";
import {
  Button
} from "reactstrap";

// import
import Loading from "Component/common/Loading";
import fileUploader from "utilities/fileUploader";
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Removeicon from "../../../../assets/svg/removeicon.svg";

const ThumbNailUploader = ({setThumbNailImage  , thumbNailImage}) => {

  const [thumbNailImageUploadLoading, setThumbNailImageUploadLoading] =
    useState(false);

  const onThumbNailChange = async (imageList, addUpdateIndex) => {
    setThumbNailImageUploadLoading(true);
    const urls = await fileUploader([imageList[addUpdateIndex].file]);
    if (!urls) {
      setThumbNailImageUploadLoading(false);
      return;
    }
    setThumbNailImage((p) => urls[0]);
    setThumbNailImageUploadLoading(false);
  };


  return (
    <div className="upload_imge_vedio">
                <ImageUploading
                  value={thumbNailImage}
                  onChange={onThumbNailChange}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <div className="images_wrap_show">
                        {thumbNailImage ? (
                          <div className="image-item position-relative">
                            <img src={thumbNailImage} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <Button
                                onClick={() => {
                                  setThumbNailImage();
                                }}
                              >
                                <img
                                  src={Removeicon}
                                  alt="Remove"
                                  className="img-fluid"
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <p className="upload_text text-white">
                            Upload ThumbNail Image
                          </p>
                        )}
                      </div>

                      <Button
                        disabled={thumbNailImageUploadLoading}
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="add_plusimg my-3"
                      >
                        {thumbNailImageUploadLoading ? (
                          <Loading fullSize={true} />
                        ) : (
                          <img src={Plussvg} alt="" className="img-fluid" />
                        )}
                      </Button>
                    </div>
                  )}
                </ImageUploading>
              </div>
  )
}

export default ThumbNailUploader