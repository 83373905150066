import { Modal } from "react-bootstrap";

// css
import styles from "./ConfirmationPop.module.scss";
import { IoIosCloseCircle } from "react-icons/io";

// img

const ImagePreviewModel = ({ previewImage, setPreviewImage }) => {
  const hideHandler = () => {
    console.log("hit1");
    setPreviewImage((p) => "");
  };
  return (
    <>
      <Modal
        show={previewImage}
        className={`${styles.ConfirmationPop} transparentModal`}
        onHide={hideHandler}
        // backdrop="static"
        keyboard={false}
        centered
        onBackdropClick={hideHandler}
      >
        <Modal.Body className={`${styles.modalBody}`}>
          <h3 className="m-0 fw-bold themeBlue d-flex justify-content-end">
            <IoIosCloseCircle onClick={hideHandler} />
          </h3>
          <div className="px-md-5 text-center mx-auto">
            <div className="btnWrpper mt-4 d-flex align-items-center justify-content-center gap-10">
              <div className="px-2 w-100">
                <img
                  src={previewImage}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImagePreviewModel;
