import Fade from "react-reveal";
import { Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import { FEEDBACK_LIST } from "services/ApiCalls";
import ViewPopUp from "./View/ViewPopUp";
import { useState } from "react";
import { truncateString } from "utilities/utilities";

const CustomerFeedback = () => {
  const [viewModel, setViewModel] = useState({ show: false, data: null });
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: FEEDBACK_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      startDate: "",
      endDate: "",
    },
    dependencies: ["startDate", "endDate"],
  });
  const StatusOptions = [
    {
      value: 0,
      label: "Pending",
    },
    {
      value: 1,
      label: "Approved",
    },
  ];
  const col = [
    {
      head: "Image",
      accessor: "email",
      component: (item) => <TableProfile image={item?.userId?.profileImage} />,
    },
    {
      head: "Name",
      accessor: "userId",
      component: (item) => (
        <>{item?.userId?.fname + " " + item?.userId?.lname}</>
      ),
    },
    {
      head: "Short Comment",
      accessor: "short_question",
      component: (item) => <>{truncateString(item?.short_question, 30)}</>,
    },
    {
      head: "Long Comment",
      accessor: "long_question",
      component: (item) => <>{truncateString(item?.long_question, 30)}</>,
    },

    {
      head: "Rating",
      accessor: "feedback",
    },
    {
      head: "Created At",
      accessor: "createdAt",
      component: (item) => (
        <>{moment(item?.createdAt).format("YYYY/MM/DD LT")}</>
      ),
    },

    {
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            viewHandler={() =>
              setViewModel((p) => ({
                show: true,
                data: item,
              }))
            }
          />
        </>
      ),
    },
  ];
  return (
    <Fade>
      <ViewPopUp model={viewModel} setModel={setViewModel} />
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Customer Feedback</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="pending_user_content mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default CustomerFeedback;
