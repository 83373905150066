import * as CONST from "./actionTypes";

export const getProfile = (payload, callBack) => {
  return {
    type: CONST.GET_PROFILE,
    payload,
    callBack,
  };
};

export const Profile_Success = (payload) => ({
  type: CONST.PROFILE_SUCCESS,
  payload,
});

export const Profile_Fail = (payload) => ({
  type: CONST.PROFILE_FAIL,
  payload,
});

