import CustomPagination from "Component/common/CustomPagination";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { MANAGE_NOTIFICATION, NOTIFICATION_LIST } from "services/ApiCalls";
import { storeNotificationId } from "store/actions";

const AllNotification = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: NOTIFICATION_LIST,
    dataToSet: (data) => data?.data,
  });
const dispatch=useDispatch()
  const handleMarkAsRead = async (id) => {
    const data = {
      _id: id,
      status: "accept",
      isUserRead: true,
      isDeleted: false, // Adjust based on your requirements
    };

    try {
      let res = await MANAGE_NOTIFICATION(data);
      toast.success(res?.data?.message);

  
      
      refetch()
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  // Handler for deleting notification
  const handleDelete = async (id) => {
  
    dispatch(storeNotificationId(id));
    const data = {
      _id: id,
      status: "accept",
      isUserRead: false,
      isDeleted: true,
    };

    try {
      let res = await MANAGE_NOTIFICATION(data);
      toast.success(res?.data?.message);

      // Optionally update local state to reflect changes
      refetch()

    } catch (error) {
      console.error("Error deleting notification", error);
    }
  };
  return (
    <section className="section-50">
      <div className="container">
        <h3 className="m-b-50 heading-line">
          Notifications <i className="fa fa-bell text-muted" />
        </h3>
        <div className="notification-ui_dd-content mt-4">
          {data?.length !== 0 ? (
            <>
              {" "}
              {data?.map((item) => {
                return (
                  <div
                    className="notification-list notification-list--unread"
                    key={item?._id}
                  >
                    <div className="notification-list_content d-flex justify-content-between align-items-start">
                      <div className="notification-list_detail">
                        <p>
                          <b>{item?.title}</b>
                        </p>
                        <p className="text-muted">{item?.message}</p>
                      </div>
                    </div>
                    <div className="notification-list_actions d-flex flex-column align-items-end ">
                      <p className="text-muted mb-1">
                        <small>{moment(item?.createdAt).fromNow()}</small>
                      </p>
                      <div>
                        {item?.isUserRead == false && (
                          <button
                            className="icon-button me-2"
                            aria-label="Mark as Read"
                            onClick={() => handleMarkAsRead(item?._id)}
                          >
                            <i className="fas fa-check-circle"></i>
                          </button>
                        )}

                        <button
                          className="icon-button"
                          aria-label="Delete"
                          onClick={() => handleDelete(item?._id)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
        <CustomPagination
          total={total}
          body={body}
          setBody={setBody}
          pageChangeHandler={paginationHandler}
        />
      </div>
    </section>
  );
};

export default AllNotification;
