import { useSelector } from "react-redux";
import * as CONST from "./actionTypes";
import { startTransition } from "react";

const intialState = {
  isLogin: false,
  loading: false,
  token: (localStorage && localStorage.getItem("token")) || null,
  _id: (localStorage && localStorage.getItem("_id")) || null,
  profileImage: (localStorage && localStorage.getItem("profileImage")) || "",
  name: (localStorage && localStorage.getItem("name")) || "",
  email: (localStorage && localStorage.getItem("email")) || "",
  notificationId:null
};

const LoginReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case CONST.LOGIN_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case CONST.LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        loading: false,
        token: payload?.data?.token,
        _id: payload?.data?.user?._id,
        profileImage: payload?.data?.user?.profileImage,
        name: payload?.data?.user?.firstName + payload?.data?.user?.lastName,
        email: payload?.data?.user?.email,
      };
    case CONST.LOGIN_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        isLogin: false,
        token: null,
        _id: null,
      };

    case CONST.LOGOUT_ADMIN:
      return {
        ...state,
        loading: false,
        isLogin: false,
        token: null,
        _id: null,
      };

    case CONST.UPDATE_PROFILE:
      localStorage.setItem("profileImage", payload?.profileImage);
      localStorage.setItem("name", payload?.firstName + " " + payload.lastName);
      localStorage.setItem("email", payload?.email);
      return {
        ...state,
        profileImage: payload?.profileImage,
        name: payload?.firstName +  " "+ payload.lastName,
        email: payload?.email,
      };
      case CONST.STORE_NOTIFICATION_ID_SUCCESS:
        return {
            ...state,
            notificationId: payload,
            error: null,
        };
    case CONST.STORE_NOTIFICATION_ID_FAILURE:
        return {
            ...state,
            error: payload,
        };
    default:
      return state;

      break;
  }
};

export default LoginReducer;
