import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import {
  MANAGE_TALENT,
  GET_TALENT_LIST,
  REPORT_ISSUES_USER_LIST,
  MANAGE_REPORT_ISSUES_USER,
} from "services/ApiCalls";
import Plussvg from "../../../../assets/svg/plussvg.svg";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import { useSelector } from "react-redux";

const talenttable = () => {
  const navigate = useNavigate();
  const permission = useSelector((s) => s?.profileData?.subAdminPermission);

  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: REPORT_ISSUES_USER_LIST,
    dataToSet: (data) => data?.data,
  });

  const col = [
    {
      head: "Issues",
      accessor: "name",
    },
    {
      head: "Craeted At",
      accessor: "createdAt",
      component: (item) => moment(item?.createdAt).format("YYYY/MM/DD HH:mm"),
    },
  ];

  if (permission != null ? permission?.isDelete : true) {
    col.push({
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () =>
                MANAGE_REPORT_ISSUES_USER({
                  _id: item?._id,
                  isDeleted: userStatus == 0 ? true : false,
                }),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={
            item?.isBlocked == false
              ? [
                  {
                    value: 4,
                    label: "Select",
                  },
                  {
                    value: 0,
                    label: "Delete",
                  },

                  // {
                  //   value: 1,
                  //   label: "Block",
                  // },
                ]
              : [
                  {
                    value: 4,
                    label: "Select",
                  },
                  {
                    value: 0,
                    label: "Delete",
                  },

                  // {
                  //   value: 2,
                  //   label: "Unblock",
                  // },
                ]
          }
          value={item?.isDeleted == true ? 0 : item?.isBlocked == true ? 1 : 4}
        />
      ),
    });
  }
  if (permission != null ? permission?.isAdd : true) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            editLink={`/edit-report-issues/${item?._id}`}
            // viewLink={`/view-talent/${item?._id}`}
          />
        </>
      ),
    });
  }

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head"> Manage Report Issues</h3>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 justify-content-between mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                  </div>
                </div>
              </div>
              {(permission != null ? permission?.isAdd : true) && (
                <div className="export_btn d-flex align-items-center  justify-content-end gap-3">
                  <Button
                    className="plussvg"
                    onClick={() => navigate("/add-report-issues")}
                  >
                    <img src={Plussvg} alt="" className="img-fluid" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default talenttable;
