import React from "react";
import Pendingpreview from "Pages/Profile/Pendinguser/Users/pendingpreview";


export default function Dashpendinguserpreview() {
  return (
    <>
       <Pendingpreview/>
    </>
  );
}
