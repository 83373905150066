import ImagePreviewModel from "Component/Modals/ImagePreveiwModel";
import dataHandler from "hooks/dataHandler";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { MANAGE_SUPPORT, MESSAGE_LIST } from "services/ApiCalls";
import {
  catchAsync,
  checkResponse,
  showMessageSendReceiveTime,
} from "utilities/utilities";
import dummyImg from "../../../assets/images/user.webp";
import InfiniteScroll from "react-infinite-scroll-component";

function MessageList({
  supportId,
  messageList,
  supportDetails,
  setSupportDetails,
  chatListContainer,
  scrollToBottom,
}) {
  const [resolveLoader, setResolveLoader] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const adminProfileImage =
    localStorage.getItem("profileImage") !== "undefined"
      ? localStorage.getItem("profileImage")
      : dummyImg;

  const ResolveHandler = catchAsync(
    async () => {
      setResolveLoader(true);
      const res = await MANAGE_SUPPORT({
        _id: supportId,
        status: 2,
      });
      const success = checkResponse({
        res,
        showSuccess: true,
        setLoader: setResolveLoader,
      });

      if (success) {
        setSupportDetails((p) => {
          if (p._id === supportId) {
            console.log(p, "check");

            const obj = {
              ...p,
            };
            obj.status = 2;
            return obj;
          } else {
            p;
          }
        });
      }
    },
    {
      setLoader: setResolveLoader,
    }
  );

  return (
    <>
      <ImagePreviewModel
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
      />

      <div
        className="position-absolute "
        style={{
          right: 25,
          bottom: 70,
        }}
      >
        {/* arrow down  */}
        <FaArrowAltCircleDown size={30} onClick={scrollToBottom} />
      </div>

      <div className="d-flex justify-content-between mb-2">
        <p className="mb-0 font-weight-bold mb-3 text-white">{supportDetails?.userId?.fname} {supportDetails?.userId?.lname} </p>
        {supportDetails?.status == 2 ? (
          <Button disabled>Chat Was Resolved</Button>
        ) : (
          <Button onClick={ResolveHandler} className="resolve-chat">
            {resolveLoader ? "Resolving..." : "Resolve Chat"}
          </Button>
        )}
      </div>
      {/* 
      <div
                    className="overflow-auto chat-list-scrollbar"
                    style={{
                      minHeight: "calc(100vh - 250px)",
                      maxHeight: "calc(100vh - 250px)",
                    }}
                    id="scrollableDiv"
                  >
      <InfiniteScroll
                      // dataLength={chatList?.length}
                      // next={() =>
                      //   setBody(prev => ({
                      //     ...prev,
                      //     page: prev.page + 1,
                      //   }))
                      // }
                      // // style={{
                      // //   maxHeight: "calc(100vh - 90px)",
                      // // }}
                      // hasMore={totalCount > chatList?.length ? true : false}
                      // scrollableTarget="scrollableDiv"
                    > */}

      <ul
        className="list_scroll list-unstyled text-white position-relative "
        style={{
          height: "calc(100vh - 320px)",
          marginBottom: 70,
        }}
        ref={chatListContainer}
      >
        {messageList?.map((item) => {
          return (
            <li className="d-flex justify-content-between mb-4 gap-10">
              {item.senderId === item.adminId && (
                <img
                  src={adminProfileImage}
                  alt="avatar"
                  className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                  width={40}
                />
              )}
              <div className="card mask-custom">
                <div
                  className="card-header d-flex justify-content-between py-1"
                  style={{ borderBottom: "1px solid rgba(255,255,255,.3)" }}
                >
                  <p className="fw-bold mb-0">{item?.userDetails?.name}</p>
                  <p className="text-black custom_font mb-0 m-0">
                    {showMessageSendReceiveTime(item.createdAt)}
                  </p>
                </div>
                <div className="card-body p-2 ">
                  {item.messageType === 3 ? (
                    <div
                      style={{
                        minHeight: 100,
                        minWidth: 100,
                      }}
                    >
                      <img
                        style={{
                          minHeight: 100,
                          minWidth: 100,
                          maxWidth: 100,
                          maxHeight: 100,
                        }}
                        onClick={() => setPreviewImage(item.message)}
                        src={item?.message}
                      />
                    </div>
                  ) : (
                    <p className="mb-0 custom_font">{item?.message}</p>
                  )}
                </div>
              </div>
              {item?.senderId !== item?.adminId && (
                <img
                  src={
                    item?.userId?.profileImage ||
                    supportDetails?.userId?.profileImage
                  }
                  alt="avatar"
                  className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                  width={40}
                />
              )}
            </li>
          );
        })}
      </ul>
      {/* </InfiniteScroll>
      </div> */}
    </>
  );
}

export default MessageList;
