import React, { useEffect, useState } from "react";
import Fade from "react-reveal";
import Welcomegreet from "Component/Welcomegreet";
import Newusertable from "./newusertable";
import Dashcards from "./dashcards";
import { catchAsync, checkResponse } from "utilities/utilities";
import { DASHBOARD_COUNT } from "services/ApiCalls";




const Dashboardpage = () => {
  const [dashData,setDashData] = useState();

  const getData = catchAsync(async () => {
    const res = await DASHBOARD_COUNT();
    checkResponse({
      res,
      setData: (data) => {
     
        setDashData(res?.data)
       
      },
    });
  });

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
            {/* <Welcomegreet/> */}
            <Dashcards dashData={dashData}/>
            <Newusertable/>
      </section>
    </Fade>
  );
};

export default Dashboardpage;
