import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, Button, Col, Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { Fade } from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ADD_FAQ, ADD_TERMS_CONDITION, CMS_DETAILS, FAQ_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import Loading from "Component/common/Loading";

// Zod schema for form validation
const schema = z.object({
  question: z.string().min(1, { message: "Question is required" }),
  answer: z.string().min(1, { message: "Answer is required" }),
});

const AddFaqs = () => {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [data, setData] = useState();
  const { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  // Handle form submission
  const onSubmit = async (data) => {
    setSubmitLoader(true);
    const res = await ADD_FAQ(id ? { ...data, _id: id } : data);

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/cms",
      navigate,
      setLoader: setSubmitLoader,
    });
    reset(); // Reset form after submission
  };

  const getData = catchAsync(async () => {
    const res = await FAQ_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        setData(data);
        reset({
          question: data.question || "",
          answer: data.answer || "",
        });
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <>
      <section
        className="cmspage_tab addresources comon_dashpad"
        style={{ color: "white" }}
      >
        <div className="breadcrumb_set mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/cms">Manage CMS</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Add FAQS</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="cmstabs_wrap text-start p-4 mt-5">
          <div className="articletab author_form">
            <Fade>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Question Field */}
                <Form.Group controlId="formQuestion" className="mt-3">
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("question")}
                    isInvalid={!!errors.question}
                    style={{ color: "white", backgroundColor: "#343a40" }}
                  />
                  {errors.question && (
                    <Form.Control.Feedback type="invalid">
                      {errors.question.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {/* Answer Field (using React-Quill) */}
                <Form.Group controlId="formAnswer" className="mt-3">
                  <Form.Label>Answer</Form.Label>
                  <Controller
                    name="answer"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <ReactQuill
                        theme="snow"
                        {...field}
                        onChange={field.onChange}
                        style={{ color: "white" }}
                      />
                    )}
                  />
                  {errors.answer && (
                    <Form.Text className="text-danger">
                      {errors.answer.message}
                    </Form.Text>
                  )}
                </Form.Group>

                {/* Submit Button */}
                <Col lg={12} className="mt-4">
                  <Button
                    className="export_csv w-100"
                    style={{ height: 50 }}
                    type="submit"
                  >
                    {submitLoader ? <Loading fullSize={true} /> : "Submit"}
                  </Button>
                </Col>
              </Form>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddFaqs;
