import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

// images
import Stafftheme from "../../../assets/images/stafftheme.png";
import { Controller, useForm } from "react-hook-form";
import { catchAsync, checkResponse } from "utilities/utilities";
import { RESET_PASSWORD } from "services/ApiCalls";
import { z } from "zod";
import ButtonLoader from "Component/common/ButtonLoader";
import { screens } from "../index";
import { zodResolver } from "@hookform/resolvers/zod";
import OTPInput from "react-otp-input";
import Logo from "../../../assets/images/jamme_logo.svg";


const schema = z
  .object({
    email: z.string(),
    otp: z
      .string()
      .min(4, { message: "Otp required 4 numerics" })
      .refine((data) => !isNaN(data), {
        message: "Otp should have only numerics",
      }),
    password: z.string().min(1, { message: "Password is required" }),
    confirmPassword: z
      .string()
      .min(1, { message: "Confirm Password is required" }),
  })
  .refine(
    (data) => {
      if (data.password !== data.confirmPassword) {
        return false;
      }
      return true;
    },
    {
      message: "Confirm Password should be match with password",
      path: ["confirmPassword"],
    }
  );

const ResetPass = ({ email, setScreen }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState({
    password: false,
    confirmPassword: false,
  });
  const changeShowPasswordHandler = (key) => {
    setShowPass((p) => ({
      ...p,
      [key]: !p[key],
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email,
    },
  });

  const submitHandler = catchAsync(
    async (data) => {
      setLoader(true);
      const res = await RESET_PASSWORD(data);

      checkResponse({
        res,
        showSuccess: true,
        navigate,
        setLoader,
        navigateUrl: "/",
      });
    },
    { setLoader }
  );

  return (
    <Container>
      <div className="form_Side">
        <a
          //   to="#"
          className="back_arrow"
          onClick={() => setScreen(screens.forgetPass)}
        >
          {backicon}
        </a>

        <form
          id="loginform"
          className="spinner-content forgot_password position-relative"
          onSubmit={handleSubmit(submitHandler)}
        >
      

          <div className="row">
          <div className="login_heading text-center">
            <img src={Logo} alt="" className="img-fluid" />
          </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Enter OTP</label>
                <div className="otpWrp">
                  <Controller
                    control={control}
                    name="otp"
                    render={({ field }) => {
                      return (
                        <OTPInput
                          {...field}
                          numInputs={4}
                          renderInput={(props) => <input {...props} />}
                        />
                      );
                    }}
                  />
                </div>
                {errors?.otp && (
                  <p className="text-danger m-0">{errors.otp.message}</p>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Enter New Password</label>
                <input
                  type={!showPass.password && "password"}
                  name="password"
                  placeholder="Enter Password"
                  {...register("password")}
                />
                <Button
                  className="btn_eye"
                  type="button"
                  onClick={() => changeShowPasswordHandler("password")}
                >
                  {showPass.password ? <>{eyeopen}</> : <>{eyeclose}</>}
                </Button>
              </div>
              {errors?.password && (
                <p className="text-danger m-0">{errors.password.message}</p>
              )}
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Confirm Password</label>
                <input
                  name="password"
                  type={!showPass.confirmPassword && "password"}
                  placeholder="Enter Password"
                  {...register("confirmPassword")}
                />
                <Button
                  className="btn_eye"
                  type="button"
                  onClick={() => changeShowPasswordHandler("confirmPassword")}
                >
                  {showPass.confirmPassword ? <>{eyeopen}</> : <>{eyeclose}</>}
                </Button>
              </div>
              {errors?.confirmPassword && (
                <p className="text-danger m-0">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>

            <div className="col-lg-12 text-start">
              <Button disabled={loader} type="submit" className="themebtn d-flex justify-content-center">
                {loader ? <ButtonLoader /> : "Update"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default ResetPass;

// svg
const backicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
      fill="black"
    />
  </svg>
);

const eyeopen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
    />
  </svg>
);

const eyeclose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
    />
  </svg>
);
