import React from "react";
import Loading from "../Loading";
import { Table } from "reactstrap";
import Nodatagif from "../../../assets/images/Nodat-found.gif";

const TableLayout = ({ column, data, loader,body }) => {
  return (
    <>
      <div className="table_wrap">
        <Table responsive>
          <thead className="sticky-top">
            <tr>
              <th>Sr no.</th>
              {column &&
                column.length > 0 &&
                column.map((item, key) => (
                  <>
                    <th>{item.head}</th>
                  </>
                ))}
            </tr>
          </thead>
          <tbody>
            {!loader &&
              data &&
              data?.length > 0 &&
              data.map((data, columnkey) => {
                return (
                  <tr>
                    <td>{(body?.page - 1) * 10 + columnkey + 1}</td>
                    {column &&
                      column.length > 0 &&
                      column.map((item, key) => {
                        if (item.component) {
                          return (
                            <td>{item.component(data, columnkey, data)}</td>
                          );
                        }

                        return <td>{data[item?.accessor]}</td>;
                      })}
                  </tr>
                );
              })}
            {loader && (
              <tr>
                <td className="text-center" colSpan={column?.length + 1}>
                  <Loading />
                </td>
              </tr>
            )}

            {!loader && !!!data?.length && (
              <tr>
                <td className="text-center" colSpan={column?.length + 1}>
                  <div className="no_data text-center">
                    <img src={Nodatagif} alt="" className="img-fluid" />
                    <p>No Data Found!</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TableLayout;
