import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Usertabbing from "./usertabbing";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import dataHandler from "hooks/dataHandler";
import { USERS_LIST } from "services/ApiCalls";
import SearchFilter from "Component/common/SearchFilter";
import UserTypeFilter from "Component/common/UserTypeFilter";
import SortByFilter from "Component/common/SortByFilter";

const Manageusers = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
    statusChangeHandler
  } = dataHandler({
    api: USERS_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      accountType: "",
      userStatus: "approved",
    },
    dependencies: ["userStatus", "order", "accountType"],
  });

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Users</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <SearchFilter body={body} setBody={setBody} />
                    <UserTypeFilter body={body} setBody={setBody} />
                  </div>
                  <h5>Sort by :</h5>

                  <SortByFilter setBody={setBody} orderByKey="fname" />
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <Usertabbing
            setBody={setBody}
            body={body}
            total={total}
            data={data}
            paginationHandler={paginationHandler}
            loader={loader}
            statusChangeHandler={statusChangeHandler}
          />
        </div>
      </section>
    </Fade>
  );
};

export default Manageusers;
