import React, { useState } from "react";
import Pendingtable from "./pendingtable";

// images
import Nodatagif from "../../../assets/images/Nodat-found.gif";

const Usertabbing = ({
  setBody,
  body,
  data,
  total,
  paginationHandler,
  loader,
}) => {
  return (
    <section className="tabbing_common">
      <div className="tab-frame user_frame">
        <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="pending"
            checked={body?.status == 1}
            onClick={() => setBody((p) => ({ ...p, status: 1 }))}
          />
          <label for="pending">Approved</label>

          <input
            type="radio"
            name="tab"
            id="decline"
            checked={body?.status == 2}
            onClick={() => setBody((p) => ({ ...p, status: 2 }))}
          />
          <label for="decline">Decline</label>
        </div>

        <div className="pending_user_content mt-4">
          <Pendingtable
            data={data}
            total={total}
            body={body}
            setBody={setBody}
            paginationHandler={paginationHandler}
            loader={loader}
          />
        </div>
      </div>
    </section>
  );
};

export default Usertabbing;
