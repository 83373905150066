import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Form, FormGroup } from "reactstrap";

// images
import { TALENT_DETAILS } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";

const TalentDetails = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const getData = catchAsync(async () => {
    const res = await TALENT_DETAILS(id);
    checkResponse({
      res,
      setData: (data) => {
        const dataToSet = data;
        setData(dataToSet);
      },
    });
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">View Details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(-1)}>
                  <Link to="#">Manage Talent</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>View Details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form mt-5">
                <Row>
                  <h5 className="text-white">Name</h5>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative flex_fields">
                      <p className="text_line">{data?.name}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <h5></h5>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default TalentDetails;
