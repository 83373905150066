
import CustomerFeedback from "Pages/Profile/CustomerFeedback";
import React from "react";





export default function Dashcustomerfeedbacktheme() {
  return (
    <>
       <CustomerFeedback/>
    </>
  );
}
