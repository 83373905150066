import Fade from "react-reveal";
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  UncontrolledDropdown,
} from "reactstrap";

import { DatePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import { EVENT_LIST, MANAGE_EVENTS } from "services/ApiCalls";
import Searchicon from "../../../../assets/svg/search_icon.svg";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import { eventStatusOptions } from "utilities/const";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import moment from "moment";
import TableActions from "Component/common/TableActions";
import { truncateString } from "utilities/utilities";
import { useSelector } from "react-redux";

const Pendingevents = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: EVENT_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      status: 0,
      startDate: "",
      endDate: "",
    },
    dependencies: ["endDate"],
  });
  const permission = useSelector((s) => s?.profileData);

  const col = [
    {
      head: "Banner Image",
      accessor: "email",
      component: (item) => <TableProfile image={item?.bannerImage} />,
    },
    {
      head: "User Name",
      accessor: "",
      component: (item) => (
        <>{item?.userId?.fname + " " + item?.userId?.lname}</>
      ),
    },
    {
      head: "Name",
      accessor: "",
      component: (item) => <>{item?.name}</>,
    },
    {
      head: "Description",
      accessor: "description",
      component: (item) => <>{truncateString(item?.description, 15)}</>,
    },
    {
      head: "Address",
      accessor: "address",
      component: (item) => <>{truncateString(item?.address, 20)}</>,
    },
    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () =>
                MANAGE_EVENTS(
                  reason
                    ? { ...reason, _id: item?._id, status: +userStatus }
                    : { _id: item?._id, status: +userStatus }
                ),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={eventStatusOptions}
          value={item?.status}
          showRejectModel={true}
        />
      ),
    },
  ];
  if (
    permission
      ? permission?.pendingRequestSubViewNames?.includes("Pending Events")
      : true
  ) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => (
        <>
          <TableActions
            // editLink={`/edit-event/${item?._id}`}
            viewLink={`/view-event/${item?._id}`}
          />
        </>
      ),
    });
  }
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Event Requests</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                    <DatePickerField
                      label="Start Date"
                      placeholder="Start Date"
                      value={body?.startDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          startDate: date && moment(date).format("YYYY/MM/DD"),
                        }))
                      }
                    />
                    <DatePickerField
                      label="End Date"
                      placeholder="End Date"
                      value={body?.endDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          endDate: date && moment(date).format("YYYY/MM/DD"),
                          // endDate: date,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>

              {/* <div className="export_btn">
                <Button className="export_csv">Export CSV</Button>
              </div> */}
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="pending_user_content mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default Pendingevents;
