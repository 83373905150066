import { all, fork } from "redux-saga/effects";

//public
import LoginSaga from "./Login/saga";
import ProfileDetailSaga from "./Profile/saga"; // import ProfileDetailSaga

export default function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(ProfileDetailSaga), // add ProfileDetailSaga
  ]);
}
