import React, { useState } from "react";
import Fade from "react-reveal";
import { Button, Form } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Aspiringtable from "./Notifytables/aspiringtable";

// images
import Plussvg from "../../../assets/svg/plussvg.svg";
import Nodatagif from "../../../assets/images/Nodat-found.gif";

// Account types
const ACCOUNT_TYPE = {
  RISING_STAR: 1,
  PROFESSIONAL: 2,
  EXECUTIVE: 3,
  QUICK_REGISTER: 4,
  SUBADMIN: 5,
};

const Notification = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(ACCOUNT_TYPE.RISING_STAR);

  const renderTabContent = () => {
    switch (activeTab) {
      case ACCOUNT_TYPE.RISING_STAR:
        return <Aspiringtable activeTab={activeTab}  />;
      case ACCOUNT_TYPE.PROFESSIONAL:
        return <Aspiringtable activeTab={activeTab}/>;

      case ACCOUNT_TYPE.EXECUTIVE:
        return <Aspiringtable activeTab={activeTab}/>;

      case ACCOUNT_TYPE.QUICK_REGISTER:
        return <Aspiringtable activeTab={activeTab}/>;


      case ACCOUNT_TYPE.SUBADMIN:
        return <Aspiringtable activeTab={activeTab}/>;


      default:
        return null;
    }
  };

  return (
    <Fade>
      <section className="Notification_page subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Notification</h3>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="plussvg"
                onClick={() => navigate("/add-notification")}
              >
                <img
                  src={Plussvg}
                  alt="Add Notification"
                  className="img-fluid"
                />
              </Button>
            </div>
            <div className="fillter_form_wrap d-flex align-items-center gap-3 justify-content-between">
              <div className="tab-frame notificationtab_frame my-4">
                <div className="clearfix">
                  <input
                    type="radio"
                    name="tab"
                    id="rising_star"
                    checked={activeTab === ACCOUNT_TYPE.RISING_STAR}
                    onChange={() => setActiveTab(ACCOUNT_TYPE.RISING_STAR)}
                  />
                  <label htmlFor="rising_star" style={{ fontSize: "18px" }}>
                    Rising Star
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="professional"
                    checked={activeTab === ACCOUNT_TYPE.PROFESSIONAL}
                    onChange={() => setActiveTab(ACCOUNT_TYPE.PROFESSIONAL)}
                  />
                  <label htmlFor="professional" style={{ fontSize: "18px" }}>
                    Professional
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="executive"
                    checked={activeTab === ACCOUNT_TYPE.EXECUTIVE}
                    onChange={() => setActiveTab(ACCOUNT_TYPE.EXECUTIVE)}
                  />
                  <label htmlFor="executive" style={{ fontSize: "18px" }}>
                    Executive
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="quick_register"
                    checked={activeTab === ACCOUNT_TYPE.QUICK_REGISTER}
                    onChange={() => setActiveTab(ACCOUNT_TYPE.QUICK_REGISTER)}
                  />
                  <label htmlFor="quick_register" style={{ fontSize: "18px" }}>
                    Quick Register
                  </label>

                  <input
                    type="radio"
                    name="tab"
                    id="subadmin"
                    checked={activeTab === ACCOUNT_TYPE.SUBADMIN}
                    onChange={() => setActiveTab(ACCOUNT_TYPE.SUBADMIN)}
                  />
                  <label htmlFor="subadmin" style={{ fontSize: "18px" }}>
                    Subadmin
                  </label>
                </div>
                <div className="export_btn "></div>
              </div>
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset ">
          <div className="common_notification">{renderTabContent()}</div>
        </div>
      </section>
    </Fade>
  );
};

export default Notification;
