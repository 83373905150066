import Fade from "react-reveal";
import { Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableLayout from "Component/common/TableLayout";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import dataHandler from "hooks/dataHandler";
import {
  MANAGE_POSTS,
  MANAGE_REPORT_USER,
  POST_LIST,
  REPORT_USER_LIST,
} from "services/ApiCalls";
import TableProfile from "Component/common/TableLayout/TableProfile";
import { commonStatusOptions } from "utilities/const";
import moment from "moment";
import TableActions from "Component/common/TableActions";
import Plussvg from "../../../assets/svg/plussvg.svg";
import { truncateString } from "utilities/utilities";
import { Button } from "react-bootstrap";

const Reportuser = () => {
  const reportStatusOptions = [
    {
      value: "Reviewed",
      label: "Reviewed",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
    {
      value: "Dismissed",
      label: "Dismissed",
    },
  ];
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: REPORT_USER_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      startDate: "",
      endDate: "",
      type: "ROOM",
    },
    dependencies: ["endDate", "type"],
  });

  const colUSer = [
    {
      head: "Name",
      accessor: "",
      component: (item) => (
        <>{(item?.userId?.fname || "-") + " " + (item?.userId?.lname || "-")}</>
      ),
    },
    {
      head: "User Name",
      accessor: "userName",
      component: (item) => (
        <>{item?.userId?.userName ? item?.userId?.userName : "-"}</>
      ),
    },
    {
      head: "Reported By",
      accessor: "",
      component: (item) => (
        <>{item?.reportedBy?.userName ? item?.reportedBy?.userName : "-"}</>
      ),
    },
    {
      head: "Reason",
      accessor: "reason",
    },
    {
      head: "Comment",
      accessor: "comment",
      component: (item) => <>{truncateString(item?.comment, 15)}</>,
    },
    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () => MANAGE_REPORT_USER({ _id: item?._id, status: userStatus }),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={reportStatusOptions}
          value={item?.status}
        />
      ),
    },
    // {
    //   head: "Action",
    //   accessor: "",
    //   component: (item) => (
    //     <>
    //       <TableActions viewLink={`/view-post/${item?._id}`} />
    //     </>
    //   ),
    // },
  ];
  const col = [
    {
      head: "Reported By",
      accessor: "",
      component: (item) => (
        <>{item?.reportedBy?.userName ? item?.reportedBy?.userName : "-"}</>
      ),
    },
    {
      head: "Reported To",
      accessor: "",
      component: (item) => (
        <>
          {item?.roomId?.userId?.userName ||
            item?.pitchId?.userId?.userName ||
            item?.postId?.userId?.userName ||
            item?.eventId?.userId?.userName}
        </>
      ),
    },
    {
      head: "Reason",
      accessor: "reason",
    },

    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () => MANAGE_REPORT_USER({ _id: item?._id, status: userStatus }),
              index,
              "status",
              +userStatus,
              true
            );
          }}
          statusOptionArr={reportStatusOptions}
          value={item?.status}
        />
      ),
    },
    // {
    //   head: "Action",
    //   accessor: "",
    //   component: (item) => (
    //     <>
    //       <TableActions viewLink={`/view-post/${item?._id}`} />
    //     </>
    //   ),
    // },
  ];

  console.log(data);
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Reports</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4 justify-content-between">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                    {/* <DatePickerField
                      label="Start Date"
                      placeholder="Start Date"
                      value={body?.startDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          startDate: date && moment(date).format("YYYY/MM/DD"),
                        }))
                      }
                    />
                    <DatePickerField
                      label="End Date"
                      placeholder="End Date"
                      value={body?.endDate}
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          endDate: date && moment(date).format("YYYY/MM/DD"),
                        }))
                      }
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="tab2"
                  checked={body.type == "ROOM"}
                  onClick={() =>
                    setBody((prev) => ({
                      ...prev,
                      type: "ROOM",
                    }))
                  }
                />
                <label for="tab2">Chat Room</label>
                <input
                  type="radio"
                  name="tab"
                  id="tab3"
                  checked={body.type == "PITCH"}
                  onClick={() =>
                    setBody((prev) => ({
                      ...prev,
                      type: "PITCH",
                    }))
                  }
                />
                <label for="tab3">Pitch</label>
                <input
                  type="radio"
                  name="tab"
                  id="tab4"
                  checked={body.type == "POST"}
                  onClick={() =>
                    setBody((prev) => ({
                      ...prev,
                      type: "POST",
                    }))
                  }
                />
                <label for="tab4">Post</label>
                <input
                  type="radio"
                  name="tab"
                  id="tab5"
                  checked={body.type == "EVENT"}
                  onClick={() =>
                    setBody((prev) => ({
                      ...prev,
                      type: "EVENT",
                    }))
                  }
                />
                <label for="tab5">Event</label>
              </div>

              {/* {state.tab == "tab1" && ( */}
              <div className="pending_user_content mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={body?.type == "USER" ? colUSer : col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
              {/* )} */}

              {/* {state.tab == "tab2" && (
          <div className="pending_user_content mt-4">

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )} */}
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default Reportuser;
