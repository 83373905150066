import { put, call, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACTION from "./actions";
import { toast } from "react-toastify";
import { LOGIN_ADMIN } from "../../services/ApiCalls";

function* loginUserSaga({ payload, callBack }) {
  try {
    const response = yield call(LOGIN_ADMIN, payload);
   
    
    if (response.status == 200) {
      toast.dismiss();
      toast.success(response?.data?.message);
      localStorage.setItem("token", response?.data?.data?.token || "");
      localStorage.setItem("_id", response?.data?.data?._id);
      localStorage.setItem("profileImage", response?.data?.data?.profileImage);
      localStorage.setItem(
        "name",
        response?.data?.data?.firstName + " " + response?.data?.data?.lastName
      );
      localStorage.setItem("email", response?.data?.data?.email);
      localStorage.setItem("role", response?.data?.data?.role);
      callBack && callBack();
      yield put(ACTION.loginAdmin_Success(response?.data));
    } else {
      toast.dismiss();
      toast.error(response?.data?.data);
      yield put(ACTION.loginAdmin_Fail(error));
    }
  } catch (error) {
    yield put(ACTION.loginAdmin_Fail(error));
  }
}

function* storeNotificationIdSaga(action) {
  try {
      const notificationId = action.payload;

      // Here you can call an API or perform any asynchronous operation
      // For example: yield call(api.storeNotificationId, notificationId);

      // If successful, dispatch success action
      yield put({ type: CONST.STORE_NOTIFICATION_ID_SUCCESS, payload: notificationId });
  } catch (error) {
      // Handle any error that occurred during the operation
      yield put({ type: CONST.STORE_NOTIFICATION_ID_FAILURE, payload: error.message });
  }
}


function* LoginSaga() {
  yield takeEvery(CONST.LOGIN_ADMIN, loginUserSaga);
  yield takeEvery(CONST.STORE_NOTIFICATION_ID, storeNotificationIdSaga);

}

export default LoginSaga;
