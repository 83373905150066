import { put, call, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACTION from "./actions";
import { toast } from "react-toastify";
import { ADMIN_PROFILE} from "../../services/ApiCalls";

function* ProfileSaga({ payload, callBack }) {
  try {
    const response = yield call(ADMIN_PROFILE, payload);

    if (response.status == 200) {
      toast.dismiss();
      // toast.success(response?.data?.message);

      callBack && callBack();
      yield put(ACTION.Profile_Success(response?.data));
    } else {
      toast.dismiss();
      toast.error(response?.data?.data);
      yield put(ACTION.Profile_Fail(error));
    }
  } catch (error) {
    yield put(ACTION.Profile_Fail(error));
  }
}

function* ProfileDetailSaga() {
  yield takeEvery(CONST.GET_PROFILE, ProfileSaga);
}

export default ProfileDetailSaga;
