import { Container, Button } from "react-bootstrap";
import CustomPagination from "Component/common/CustomPagination";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import TableStatusOptionsAction from "Component/common/TableStatusOptionsAction";
import { userStatusOptionArr } from "utilities/const";
import { MANAGE_USER } from "services/ApiCalls";
import { removeUnderScore } from "utilities/utilities";
import { useNavigate } from "react-router-dom";

import TableActions from "Component/common/TableActions";
import { useSelector } from "react-redux";

const Pendingtable = ({
  data,
  total,
  body,
  setBody,
  paginationHandler,
  loader,
  statusChangeHandler,
}) => {
  const navigate = useNavigate();
  const permission = useSelector((s) => s?.profileData);
  const col = [
    {
      head: "Picture",
      accessor: "email",
      component: (item) => <TableProfile image={item?.profileImage} />,
    },
    {
      head: "Name",
      accessor: "",
      component: (item) => (
        <>
          {item?.fname} {item?.lname}
        </>
      ),
    },
    {
      head: "Username",
      accessor: "",
      component: (item) => <>{item?.userName}</>,
    },
    {
      head: "Phone",
      accessor: "mobileNumber",

      component: (item) => (
        <>
          {item?.mobileNumber
            ? (item?.countryCode ? "+" + item?.countryCode + " " : "") +
              item?.mobileNumber
            : "-"}
        </>
      ),
    },
    {
      head: "Email",
      accessor: "email",
    },
    {
      head: "User Type",
      accessor: "accountType",
      component: (item) => <>{removeUnderScore(item?.accountType)}</>,
    },
    {
      head: "Status",
      accessor: "accountType",
      component: (item, index) => (
        <TableStatusOptionsAction
          onChange={(userStatus, reason) => {
            statusChangeHandler(
              () =>
                MANAGE_USER(
                  reason
                    ? { ...reason, _id: item?._id, userStatus }
                    : { _id: item?._id, userStatus }
                ),
              index,
              "userStatus",
              userStatus,
              true
            );
          }}
          statusOptionArr={userStatusOptionArr}
          value={item?.userStatus}
          showRejectModel={true}
        />
      ),
    },
  ];
  if (
    permission?.pendingRequestSubViewNames?.length !== 0
      ? permission?.pendingRequestSubViewNames?.includes("Pending Users")
      : true
  ) {
    col.push({
      head: "Action",
      accessor: "",
      component: (item) => <TableActions viewLink={`/view-user/${item._id}`} />,
    });
  }
  return (
    <section className="Diagnosis_table com_table ">
      <Container>
        <TableLayout data={data} column={col} loader={loader} body={body} />
        <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
          <CustomPagination
            total={total}
            body={body}
            setBody={setBody}
            pageChangeHandler={paginationHandler}
          />
        </div>
      </Container>
    </section>
  );
};

export default Pendingtable;
