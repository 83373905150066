import React, { useState } from "react";
import Fade from "react-reveal";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button, Form, FormGroup, FormLabel, FormControl, Col, Row } from "react-bootstrap";
import { catchAsync, checkResponse } from "utilities/utilities";
import { ADD_EDIT_PUSH_NOTIFICATION } from "services/ApiCalls";
import { useNavigate } from "react-router-dom";

// Define ACCOUNT_TYPE constants
const ACCOUNT_TYPE = {
  RISING_STAR: 1,
  PROFESSIONAL: 2,
  EXECUTIVE: 3,
  QUICK_REGISTER: 4,
  SUBADMIN: 5,
};

// Zod schema for validation
const notificationSchema = z.object({
  notifytitle: z.string().min(1, "Notification title is required"),
  notifymessage: z.string().min(1, "Notification message is required"),
});

const AddNotification = () => {
  const [activeTab, setActiveTab] = useState(ACCOUNT_TYPE.RISING_STAR);
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);

  // Initialize useForm with Zod schema resolver
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(notificationSchema),
  });

  // Handle form submission
  const onSubmit = async (data) => {
    setSubmitLoader(true);
    const res = await ADD_EDIT_PUSH_NOTIFICATION({
      title: data?.notifytitle,
      accountType: activeTab,
      message: data?.notifymessage,
    });

    checkResponse({
      res,
      showSuccess: true,
      navigateUrl: "/notification",
      navigate,
      setLoader: setSubmitLoader,
    });
    reset(); // Reset form after submission
  };

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Manage Notification</h5>
            </div>

            {/* Tab Section */}
            <div className="tab-frame notificationtab_frame my-4">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="rising_star"
                  checked={activeTab === ACCOUNT_TYPE.RISING_STAR}
                  onClick={() => setActiveTab(ACCOUNT_TYPE.RISING_STAR)}
                />
                <label htmlFor="rising_star">Rising Star</label>

                <input
                  type="radio"
                  name="tab"
                  id="professional"
                  checked={activeTab === ACCOUNT_TYPE.PROFESSIONAL}
                  onClick={() => setActiveTab(ACCOUNT_TYPE.PROFESSIONAL)}
                />
                <label htmlFor="professional">Professional</label>

                <input
                  type="radio"
                  name="tab"
                  id="executive"
                  checked={activeTab === ACCOUNT_TYPE.EXECUTIVE}
                  onClick={() => setActiveTab(ACCOUNT_TYPE.EXECUTIVE)}
                />
                <label htmlFor="executive">Executive</label>

                <input
                  type="radio"
                  name="tab"
                  id="quick_register"
                  checked={activeTab === ACCOUNT_TYPE.QUICK_REGISTER}
                  onClick={() => setActiveTab(ACCOUNT_TYPE.QUICK_REGISTER)}
                />
                <label htmlFor="quick_register">Quick Register</label>

                <input
                  type="radio"
                  name="tab"
                  id="subadmin"
                  checked={activeTab === ACCOUNT_TYPE.SUBADMIN}
                  onClick={() => setActiveTab(ACCOUNT_TYPE.SUBADMIN)}
                />
                <label htmlFor="subadmin">Subadmin</label>
              </div>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3 mt-4">
              <div className="author_form">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <FormLabel htmlFor="notifytitle">Notification Title</FormLabel>
                      <FormControl
                        type="text"
                        name="notifytitle"
                        id="notifytitle"
                        placeholder="Enter Title"
                        {...register("notifytitle")}
                        isInvalid={!!errors.notifytitle}
                        style={{ color: "white" }}
                      />
                      {errors.notifytitle && (
                        <p className="text-danger mt-2">{errors.notifytitle.message}</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <FormLabel htmlFor="notifymessage">Notification Message</FormLabel>
                      <FormControl
                        type="text"
                        name="notifymessage"
                        id="notifymessage"
                        placeholder="Enter Message"
                        {...register("notifymessage")}
                        isInvalid={!!errors.notifymessage}
                        style={{ color: "white" }}
                      />
                      {errors.notifymessage && (
                        <p className="text-danger mt-2">{errors.notifymessage.message}</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={3} md={6} sm={12} className="offset-lg-9 mt-3">
                    <Button className="export_csv w-100" type="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default AddNotification;
