import React, { useState, useEffect } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ResponsiveLine } from "@nivo/line";

// images
import { GET_ANALTICS_DATA } from "services/ApiCalls";
import dataHandler from "hooks/dataHandler";

const Dataanalytics = () => {
  const navigate = useNavigate();

  const [filterType, setFilterType] = useState("Weekly"); // Set initial filter
  const [dataType, setDataType] = useState("Post"); // Set initial data type (e.g., Post, Event, Pitch)
  // const [loading, setLoading] = useState(true);

  const {
    body,
    setBody,
    data,
    refetch,
    loader
  } = dataHandler({
    api: GET_ANALTICS_DATA,
    dataToSet: (data) => data?.data,
    extraBody: { filterType, dataType },
    dependencies: ["filterType", "dataType"], // Refetch data when filterType or dataType changes
  });

  // useEffect(() => {
  //   if (data) {
  //     setLoading(false);
  //   }
  // }, [data]);

  // Format data for Nivo chart
  const formattedData = data?.map((item) => ({
    x: filterType=="Monthly"?item?.day:filterType=="Yearly"?item?.month:item?.dayName,
    y: item?.count,
  }));

  const lineChartData = [
    {
      id: dataType,
      color: 'hsl(12, 70%, 50%)',
      data: formattedData || [],
    },
  ];

  // Function to change filter type (Weekly, Monthly, Yearly)
  const handleFilterChange = (newFilter) => {
    // setLoading(true);
    setFilterType(newFilter);
    setBody((p) => ({ ...p, filterType: newFilter }));

  };

  // Function to change data type (Post, Event, Pitch)
  const handleDataTypeChange = (newDataType) => {
    // setLoading(true);
    setDataType(newDataType);
    setBody((p) => ({ ...p, dataType: newDataType }));

  };

  return (
    <Fade>
      <section className="subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Data & Analytics</h3>
            </div>
          </div>
        </Form>

        <div className="reported_reason mt-4">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="data_ananlytics_card">
              


                <div className="top_header">
              
                <h4>Total {dataType}</h4>

                <div className="d-flex align-items-center justify-content-end">

                <UncontrolledDropdown className="me-3">
                    <DropdownToggle caret>{filterType}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleFilterChange("Weekly")}>
                        Weekly
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterChange("Monthly")}>
                        Monthly
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFilterChange("Yearly")}>
                        Yearly
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown>
                    <DropdownToggle caret>{dataType}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleDataTypeChange("Post")}>
                        Post
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDataTypeChange("Pitch")}>
                        Pitch
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDataTypeChange("Event")}>
                        Event
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
            
                </div>

                <div className="innner_dark_card rounded-3 p-3 mt-4" style={{ height: "400px" }}>
                  {loader ? (
                    <div className="d-flex justify-content-center">

                    <Spinner style={{ width: '3rem', height: '3rem' }} />

                    </div>
                  ) : (
                    <ResponsiveLine
                    data={lineChartData}
                    margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                      type: "linear",
                      min: "auto",
                      max: "auto",
                      stacked: true,
                      reverse: false,
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendOffset: 36,
                      legendPosition: "start",
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Count",
                      legendOffset: -40,
                      legendPosition: "middle",
                    }}
                    colors={{ scheme: "dark2" }} // Dark theme colors
                    lineWidth={3}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                      {
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: 50,
                        itemsSpacing: 0,
                        itemDirection: "left-to-right",
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: "circle",
                        symbolBorderColor: "rgba(255, 255, 255, 0.5)",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemBackground: "rgba(255, 255, 255, 0.1)",
                              itemOpacity: 1,
                              margin: "10px",
                            },
                          },
                        ],
                      },
                    ]}
                    theme={{
                      textColor: "#ffffff", // Make all text white
                      axis: {
                        domain: {
                          line: {
                            stroke: "#777777", // Grey for axis line
                          },
                        },
                        legend: {
                          text: {
                            fill: "#ffffff", // White for axis legends
                          },
                        },
                        ticks: {
                          line: {
                            stroke: "#777777", // Grey for tick lines
                            strokeWidth: 1,
                          },
                          text: {
                            fill: "#ffffff", // White for tick text
                          },
                        },
                      },
                      legends: {
                        text: {
                          fill: "#ffffff", // White legend text
                        },
                      },
                    }}
                    enableGridX={false} // Remove vertical grid lines
                    enableGridY={false} // Remove horizontal grid lines
                  />
                  
                  
                  
                
                  
                  
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Fade>
  );
};

export default Dataanalytics;
