import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";

// import
import Downarrow from "../../../../../assets/svg/downarrow.svg";

// images
import Dummayimg from "../../../../../assets/images/docimg.png";

const Pendingpreview = () => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Preview details</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/manage-users">Manage Users</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Preview details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="preivew_images_wrap">
                <Slider {...settings}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, idx) => {
                    return (
                      <div className="image_preview" key={idx}>
                        <img src={Dummayimg} alt="" className="img-fluid" />
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="author_form mt-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="Category">Category</Label>
                      <Input type="select" name="select" id="Category">
                        <option hidden disabled>
                          Select Category
                        </option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="downImg">
                        <img src={Downarrow} alt="" />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="eventname">Event Name</Label>
                      {/* <Input
                        type="text"
                        name="title"
                        id="eventname"
                        placeholder="eventname"
                      /> */}
                      <p className="text_line">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Fuga, illo.
                      </p>
                      
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="eventlink">Event link</Label>
                      <Input
                        type="text"
                        name="title"
                        id="eventlink"
                        placeholder="https://www.google.com"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="Country">Country</Label>
                      <Input type="select" name="select" id="Country">
                        <option hidden disabled>
                          Country
                        </option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="downImg">
                        <img src={Downarrow} alt="" />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="startdate">Start Date</Label>
                      <Input
                        type="date"
                        name="title"
                        id="startdate"
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="enddate">End Date</Label>
                      <Input
                        type="date"
                        name="enddate"
                        id="enddate"
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="starttime">Start time</Label>
                      <Input
                        type="time"
                        name="starttime"
                        id="starttime"
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="starttime">End time</Label>
                      <Input
                        type="time"
                        name="starttime"
                        id="starttime"
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="Description">Add Description</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="Description"
                        placeholder="Type here....."
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}></Col>

                  <Col lg={6} md={6} sm={12} className="mt-4">
                    <Button className="export_csv w-100">Save</Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Pendingpreview;
