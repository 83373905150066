/////////////-----------------Login Action------------------///////////
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAIL = "LOGIN_ADMIN_FAIL";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
// actionTypes.js
export const STORE_NOTIFICATION_ID = 'STORE_NOTIFICATION_ID';
export const STORE_NOTIFICATION_ID_SUCCESS = 'STORE_NOTIFICATION_ID_SUCCESS';
export const STORE_NOTIFICATION_ID_FAILURE = 'STORE_NOTIFICATION_ID_FAILURE';



