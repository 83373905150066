import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "reactstrap";

import "rsuite/dist/rsuite.min.css";

// images
import CustomPagination from "Component/common/CustomPagination";
import DatePickerField from "Component/common/DatePickerField";
import SearchFilter from "Component/common/SearchFilter";
import TableActions from "Component/common/TableActions";
import TableLayout from "Component/common/TableLayout";
import TableProfile from "Component/common/TableLayout/TableProfile";
import dataHandler from "hooks/dataHandler";
import moment from "moment";
import { CHAT_ROOM_LIST, EVENT_LIST, PUSH_NOTIFICATION_LIST } from "services/ApiCalls";

import { truncateString } from "utilities/utilities";
import { useEffect } from "react";

const Aspiringtable = ({ activeTab }) => {
  const navigate = useNavigate();
console.log("dfddd",activeTab)
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: PUSH_NOTIFICATION_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      accountType: activeTab,
    },
    dependencies: ["accountType"],
  });
  useEffect(() => {
    // Pass the fetched data along with the accountType to setBody
    setBody({
      ...body,
      accountType: activeTab, // Include the activeTab as accountType
    });
  }, [activeTab]);

  const col = [
 

    {
      head: "Title",
      accessor: "",
      component: (item) => <>{item?.title}</>,
    },
    {
      head: "Message",
      accessor: "",
      component: (item) => (
        <>
          {item?.message}
        </>
      ),
    },

    {
      head: "Created At",
      accessor: "createdAt",
      component: (item) => <>{moment(item?.createdAt).format("YYYY/MM/DD  HH:MM ")}</>,
      
    },
    // {
    //   head: "Action",
    //   accessor: "",
    //   component: (item) => (
    //     <>
    //       <TableActions
    //         // editLink={`/edit-event/${item?._id}`}
    //         viewLink={`/view-chat-room/${item?._id}`}
    //       />
    //     </>
    //   ),
    // },
  ];

  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        
        <div className="manageuser_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="manageuser_tabbingset mt-4">
                <section className="Diagnosis_table com_table ">
                  <Container>
                    <TableLayout
                      data={data}
                      column={col}
                      loader={loader}
                      body={body}
                    />
                    <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
                      <CustomPagination
                        total={total}
                        body={body}
                        setBody={setBody}
                        pageChangeHandler={paginationHandler}
                      />
                    </div>
                  </Container>
                </section>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default Aspiringtable;
