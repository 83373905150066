import * as CONST from "./actionTypes";

export const loginAdmin = (payload, callBack) => {
  return {
    type: CONST.LOGIN_ADMIN,
    payload,
    callBack,
  };
};

export const loginAdmin_Success = (payload) => ({
  type: CONST.LOGIN_ADMIN_SUCCESS,
  payload,
});

export const loginAdmin_Fail = (payload) => ({
  type: CONST.LOGIN_ADMIN_FAIL,
  payload,
});

export const logoutAdmin = (payload) => ({
  type: CONST.LOGOUT_ADMIN,
  payload,
});

export const updateProfile = (payload) => ({
  type: CONST.UPDATE_PROFILE,
  payload,
});

export const storeNotificationId = (notificationId) => ({
  type: CONST.STORE_NOTIFICATION_ID,
  payload: notificationId,
});