import { combineReducers } from "redux";
import LoginReducer from "./Login/reducer";
import ProfileReducer from "./Profile/reducer";


const rootReducer = combineReducers({
  login: LoginReducer,
  profileData:ProfileReducer
});

export default rootReducer;
